import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import influencerList from '../data/influencerList.json';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export const InfluencerList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const ordersPerPage = 10;
  const [influencers, setInfluencers] = useState([]);
  const [open, setOpen] = useState(false);
  const [openSaveMessage, setOpenSaveMessage] = useState(false);

  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [instagram, setInstagram] = useState("");
  const [facebook, setFacebook] = useState("");
  const [x, setX] = useState("");
  const [youtube, setYoutube] = useState("");
  const [affitch, setAffitch] = useState("");

  const indexOfLast = currentPage * ordersPerPage;
  const indexOfFirst = indexOfLast - ordersPerPage;
  const currentInfluencers = influencers.slice(indexOfFirst, indexOfLast);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(influencers.length / ordersPerPage); i++) {
    pageNumbers.push(i);
  }
  // 現在のページに表示するオーダーの総数
  const totalOrders = influencers.length;
  const currentCount = currentInfluencers.length;

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  
  /**
   * 申請完了のポップアップを閉じる関数
   *
   * @param event
   * @param reason
   * @returns
   */
  const handleCloseSaveMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSaveMessage(false);
  };

  useEffect(() => {
    console.log("localStorage")
    let tmpInfluencerList = JSON.parse(localStorage.getItem("influencersPE1222"));

    setInfluencers(tmpInfluencerList ? tmpInfluencerList :influencerList);
  }, []); // 空の依存配列を渡して、コンポーネントのマウント時にのみ実行されるように

  return (
    <>
      <SummaryContainer>
        <TotalCount>総件数: {totalOrders}件</TotalCount>
        <TotalCount>表示件数: {currentCount}件</TotalCount>
      </SummaryContainer>
      <OrderListContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
              <TableCell></TableCell>
              <TableCell>氏名</TableCell>
              <TableCell>連絡先</TableCell>
              <TableCell><InstagramIcon/></TableCell>
              <TableCell><FacebookIcon/></TableCell>
              <TableCell><XIcon/></TableCell>
              <TableCell><YouTubeIcon/></TableCell>
              <TableCell>Affitch Link</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentInfluencers.map((influencer, index) => (
              <TableRow key={influencer.id}>
                <TableCell>
                  <Button
                    variant="contained"
                    sx={{ bgcolor: '#FF4455' }}
                    onClick={() => setInfluencers(prev => {
                      let tmp = [...prev];
                      tmp.splice(index, 1);
                      return tmp;
                    })}
                   >
                    削除
                  </Button>
                </TableCell>
                <TableCell>{influencer.name}</TableCell>
                <TableCell>{influencer.contact}</TableCell>
                <TableCell>{influencer.instagram ? 
                  <a href={influencer.instagram}>
                    Link
                  </a> : ""}</TableCell>
                  <TableCell>{influencer.facebook ? 
                  <a href={influencer.facebook}>
                    Link
                  </a> : ""}</TableCell>
                  <TableCell>{influencer.x ? 
                  <a href={influencer.x}>
                    Link
                  </a> : ""}</TableCell>
                  <TableCell>{influencer.youtube ? 
                  <a href={influencer.youtube}>
                    Link
                  </a> : ""}</TableCell>
                  <TableCell>{influencer.affitch ? 
                  <a href={influencer.affitch}>
                    Link
                  </a> : ""}</TableCell>
              </TableRow >
            ))}
          </TableBody>
        </Table>
        <PaginationContainer>
          {pageNumbers.map(number => (
            <PageNumber
              key={number}
              isActive={currentPage === number}
              onClick={() => setCurrentPage(number)}
            >
              {number}
            </PageNumber>
          ))}
        </PaginationContainer>
        <Stack my={2} direction="row" justifyContent="end" spacing={1}>
          <Button
            variant="contained"
            color="success"
            onClick={handleOpen}
          >
            追加
          </Button>
          <Button
            variant="contained"
            onClick={() => {localStorage.setItem("influencersPE1222", JSON.stringify(influencers));
              setOpenSaveMessage(true);
            }}
          >
            保存
          </Button>
        </Stack>
      </OrderListContainer>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 400,  display: "flex", flexDirection: "column", gap: "16px" }}>
          <TextField label="氏名" variant="outlined" fullWidth value={name} onChange={(e) => setName(e.target.value)} />
          <TextField label="連絡先" variant="outlined" fullWidth value={contact} onChange={(e) => setContact(e.target.value)} />
          <TextField label="Instagram" variant="outlined" fullWidth value={instagram} onChange={(e) => setInstagram(e.target.value)} />
          <TextField label="Facebook" variant="outlined" fullWidth value={facebook} onChange={(e) => setFacebook(e.target.value)} />
          <TextField label="X" variant="outlined" fullWidth value={x} onChange={(e) => setX(e.target.value)} />
          <TextField label="youtube" variant="outlined" fullWidth value={youtube} onChange={(e) => setYoutube(e.target.value)} />
          <TextField label="Affitch" variant="outlined" fullWidth value={affitch} onChange={(e) => setAffitch(e.target.value)} />
          <Button variant="contained"
            onClick={() => {setInfluencers(prev => {
              let tmp = [...prev];
              tmp.push({
                "id": prev.length + 1,
                "name": name,
                "contact": contact,
                "instagram": instagram,
                "facebook": facebook,
                "x": x,
                "youtube": youtube,
                "affitch": affitch
              })
              return tmp;
            });
            setName("");
            setContact("");
            setInstagram("");
            setFacebook("");
            setX("");
            setYoutube("");
            setAffitch("");
            handleClose();}}>追加</Button>
        </Box>
      </Modal>
      <Snackbar
        open={openSaveMessage}
        autoHideDuration={6000}
        onClose={handleCloseSaveMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSaveMessage} severity="success" variant="filled" sx={{ width: '100%' }}>
          保存しました。
        </Alert>
      </Snackbar>
    </>
  );
};

const OrderListContainer = styled.div`
  padding: 20px;
  border-radius: 6px;
  overflow-x: auto;
  margin-left: 15px;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const PageNumber = styled.span`
  cursor: pointer;
  padding: 5px;
  margin: 0 5px;
  background: ${({ isActive }) => (isActive ? '#555' : 'none')};
  color: ${({ isActive }) => (isActive ? '#fff' : '#ccc')};
`;

// 総件数と表示件数を表示するコンポーネント
const SummaryContainer = styled.div`
  padding: 10px 0;
  display: flex;
`;

const TotalCount = styled.span`
  margin-left:20px;
  font-size: 1rem;
`;