import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Table } from '../molecules/Table';
import { ComposedChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
// import forecastData from '../data/itemsForecast.json';
// import itemsData from '../data/itemsInfo.json';
import dayjs from 'dayjs';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
/*
 * npm install --save dayjs
 * npm install --save react-table
 */

const columns = [
  { Header: "商品", accessor: "product" },
  { Header: "予測売上個数", accessor: "demand" }
];

export const Analytics = () => {

  const [inventoryItems, setInventoryItems] = useState([]);
  const [open, setOpen] = useState(false);

  const [forecastData, setForecastData] = useState([]);
  const [oForecastByDay, setOForecastByDay] = useState([]);
  const [oForecastBySubtotal, setOForecastBySubtotal] = useState([]);
  const [days, setDays] = useState([]);
  const [term, setTerm] = useState({});
  const [forecastReason, setForecastReason] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (forecastData.length === 0 || inventoryItems.length === 0) return;

    const newDays = [];
    const newOForecastByDay = [];
    const oForecastByItem = {};

    forecastData.forEach((item, index) => {
      const day = dayjs(item.timestamp).format('MM/DD');
      if (!newDays.includes(day)) {
        newDays.push(day);
      }

      if (index === 0) {
        setTerm(prev => ({ ...prev, from: day }));
      }
      if (index === forecastData.length - 1) {
        setTerm(prev => ({ ...prev, to: day }));
      }
    });

    newDays.sort((a, b) => dayjs(a, 'MM/DD').valueOf() - dayjs(b, 'MM/DD').valueOf());

    newDays.forEach(day => {
      const dayData = { date: day };

      forecastData.forEach(forecast => {
        if (dayjs(forecast.timestamp).format('MM/DD') === day) {
          const itemId = forecast.item_id.replace('gid://shopify/ProductVariant/', '');
          dayData[itemId] = forecast.demand;
          oForecastByItem[itemId] = (oForecastByItem[itemId] || 0) + forecast.demand;
        }
      });

      newOForecastByDay.push(dayData);
    });

    const newOForecastBySubtotal = inventoryItems.map(item => {
      const itemId = item.id.replace('gid://shopify/ProductVariant/', '');
      return {
        product: item.product.title,
        price: item.price.toLocaleString(),
        demand: oForecastByItem[itemId] || 0,
        str_subtotal: ((oForecastByItem[itemId] || 0) * item.price).toLocaleString(),
        subtotal: (oForecastByItem[itemId] || 0) * item.price
      };
    });

    console.log('Daily forecast data:', newOForecastByDay);
    console.log('Subtotal data:', newOForecastBySubtotal);

    setDays(newDays);
    setOForecastByDay(newOForecastByDay);
    setOForecastBySubtotal(newOForecastBySubtotal);
  }, [forecastData, inventoryItems]);

  const itemsData = useMemo(() => {
    return inventoryItems.map(item => ({
      item_id: item.id.replace('gid://shopify/ProductVariant/', ''),
      item_name: item.product.title,
      price: item.price,
      fill: '#' + Math.floor(Math.random() * 16777215).toString(16)
    }));
  }, [inventoryItems]);

  const total = useMemo(() => {
    return oForecastBySubtotal.reduce((sum, i) => sum + (i.subtotal || 0), 0);
  }, [oForecastBySubtotal]);

  const handleOpen = async () => {

    try {
      const proxy_base_url = process.env.REACT_APP_PROXY_BASE_URL ?? 'http://localhost:3000';
      const response = await fetch(proxy_base_url + '/api/analytics/request', {
        method: "POST",
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setOpen(true);
    } catch (error) {
      console.error('Failed to fetch orders:', error);
      console.log('プロキシエンドポイントへの接続に失敗しました')
    }

  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    let isSubscribed = true;

    const fetchOrders = async () => {
      try {
        setIsLoading(true);
        const proxy_base_url = process.env.REACT_APP_PROXY_BASE_URL ?? 'http://localhost:3000';
        let response;
        response = await fetch(proxy_base_url + '/api/products');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data_products = await response.json();


        response = await fetch(proxy_base_url + '/api/orders');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data_orders = await response.json();


        if (isSubscribed) {
          setInventoryItems(data_products);

          let geminiAPIKey = "AIzaSyB7UXeaChiuDm71yK1pJEzfuNKSwCfidP4";
          const ENDPOINT = `https://generativelanguage.googleapis.com/v1beta/models/gemini-2.0-flash-exp:generateContent?key=${geminiAPIKey}`;
          let prompt = `以下に私が経営しているネットショップの在庫情報と注文履歴のデータです。

          在庫情報
          ${JSON.stringify(data_products)}

          注文履歴
          ${JSON.stringify(data_orders)}
          
          このデータを元に、本日${dayjs().format('YYYY-MM-DD')}から今後12カ月分の売上データを予想してください。
          また、売上予測に応じて今後の各商品毎の在庫管理と発注タイミング（どのタイミングで何個ずつ発注するかなど）について提案とアドバイスをしてください。
          
          返答はJSONで返してください。
          JSONのスキーマは以下の通りです。
          {
            "predictions": [
              prediction // 各商品ごとに12カ月なので、個数は(商品の種類)*12になる
            ],
            "reason": text //予測した根拠を日本語で説明。過去の販売ペースを参考にする。今後の在庫管理と発注タイミングについて提案とアドバイスも記述する。800文字程度。
          }

          prediction
          {
              "item_id": id,
              "timestamp": datetime, // 本日以降の月初の日付、1カ月ずつ増やす。
              "demand": int // 1月毎の予想売上個数
          }
          
          `;
          const payload = {
            method: 'POST',
            headers: {},
            body: JSON.stringify({
              contents: [{ parts: [{ text: prompt }] }],
              generationConfig: {
                "temperature": 1.0,
                "max_output_tokens": 4096
              },
              safetySettings: [
                {
                  "category": "HARM_CATEGORY_HATE_SPEECH",
                  "threshold": "BLOCK_NONE"
                },
                {
                  "category": "HARM_CATEGORY_DANGEROUS_CONTENT",
                  "threshold": "BLOCK_NONE"
                },
                {
                  "category": "HARM_CATEGORY_HARASSMENT",
                  "threshold": "BLOCK_NONE"
                },
                {
                  "category": "HARM_CATEGORY_SEXUALLY_EXPLICIT",
                  "threshold": "BLOCK_NONE"
                },
                {
                  "category": "HARM_CATEGORY_CIVIC_INTEGRITY",
                  "threshold": "BLOCK_NONE"
                }
              ]
            })
          };
          let analyticsData = [];
          try {
            const analyticsResponse = await fetch(ENDPOINT, payload);
            const analyticsBody = await analyticsResponse.json();
            const text = analyticsBody.candidates[0].content.parts[0].text;
            const responseData = JSON.parse(text.replace('```json', '').replace('```', '').replace(`\n`, ''));
            console.log(responseData);
            analyticsData = responseData.predictions;
            if (responseData.reason && isSubscribed) {
              setForecastReason(responseData.reason);
            }
            if (analyticsData && isSubscribed) {
              setForecastData(analyticsData);
            }
          } catch (error) {
            console.error('Failed to parse JSON:', error);
            setForecastReason(
              '申し訳ありません。予測データの解析に失敗しました。\n' +
              'これは一時的なエラーの可能性があります。\n\n' +
              '以下のいずれかの操作をお試しください：\n' +
              '• ページを再読み込みする\n' +
              '• しばらく時間をおいて再度アクセスする\n\n' +
              'エラーが続く場合は、システム管理者にお問い合わせください。'
            );
          }
        }
      } catch (error) {
        console.error('Failed to fetch orders:', error);
        console.log('プロキシエンドポイントへの接続に失敗しました');
      } finally {
        if (isSubscribed) {
          setIsLoading(false);
        }
      }
    };

    fetchOrders();

    return () => {
      isSubscribed = false;
    };
  }, []);

  return (
    <>
      <GraphContainer>
        {isLoading ? (
          <LoadingContainer>
            <CircularProgress />
            <LoadingText>予測データを生成中...</LoadingText>
          </LoadingContainer>
        ) : (
          <>
            <GraphHeader>
              <Title>売上予測・個数</Title>
              <Term>期間：{term.from} - {term.to}</Term>
            </GraphHeader>
            <GraphBody>
              <ComposedChart
                width={1200}
                height={600}
                data={oForecastByDay}
                margin={{ top: 10, right: 30, left: 20, bottom: 10 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="date"
                  tickFormatter={(value) => value}
                />
                <YAxis />
                <Tooltip />
                <Legend verticalAlign="bottom" height={36} />
                {itemsData.map((item, index) => (
                  <Line
                    key={item.item_id}
                    name={item.item_name}
                    dataKey={item.item_id}
                    type="monotone"
                    stroke={item.fill}
                    dot={false}
                  />
                ))}
              </ComposedChart>

              <Total>
                予測売上合計：¥{total.toLocaleString()}
              </Total>
              <AnalyticsBody>
                <TableContainer>
                  <Table
                    data={oForecastBySubtotal}
                    columns={columns}
                  />
                </TableContainer>
                <ReasonContainer>
                  <ReasonTitle>予測の根拠と発注の提案</ReasonTitle>
                  <ReasonText isError={forecastReason.includes('申し訳ありません')}>
                    {forecastReason}
                  </ReasonText>
                </ReasonContainer>
              </AnalyticsBody>
            </GraphBody>
          </>
        )}
      </GraphContainer>
    </>
  );
};

const GraphContainer = styled.div`
  border-radius: 10px;
  font-size: small;
  margin: 20px auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1200px;
  width: 100%;
`;
const GraphHeader = styled.div`
  margin: 10px auto;
`;
const GraphBody = styled.div`
  margin: 10px auto;
  width: 100%;
  
  .recharts-wrapper {
    margin: 0 auto;
    width: 100% !important;
    height: auto !important;
  }
`;
const Title = styled.h2`
`;
const Term = styled.div`
  font-size: small;
`;
const Total = styled.div`
  font-size: 1rem;
  margin-left: 50px;
`;
const AnalyticsBody = styled.div`
  display: flex;
  gap: 20px;
  margin: 20px 0;
`;
const TableContainer = styled.div`
  flex: 1;
`;
const ReasonContainer = styled.div`
  flex: 1;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  min-width: 300px;
`;
const ReasonTitle = styled.h3`
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 1.1rem;
  color: #333;
`;
const ReasonText = styled.p`
  margin: 0;
  font-size: 0.9rem;
  line-height: 1.6;
  white-space: pre-wrap;
  
  ${props => props.isError && `
    color: #d32f2f;
  `}
`;
const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  gap: 20px;
`;
const LoadingText = styled.div`
  font-size: 1rem;
  color: #666;
`;