import React, { useCallback } from 'react';
import { useNavigate } from "react-router-dom";
// import styled from 'styled-components';
// import { MdHome, MdInventory, MdShoppingCart, MdAccountCircle, MdExitToApp, MdAnalytics, MdClose } from 'react-icons/md';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
// import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import InventoryIcon from '@mui/icons-material/Inventory';
// import AssignmentIcon from '@mui/icons-material/Assignment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArticleIcon from '@mui/icons-material/Article';
import RateReviewIcon from '@mui/icons-material/RateReview';


// コンポーネント定義
export const MenuDrawer = () => {
  // const [isVisible, setIsVisible] = useState(true); // サイドバーの表示状態
  const Navigate = useNavigate();

  // サイドバーの表示・非表示を切り替える関数
  // const toggleSidebar = () => {
  //   setIsVisible(!isVisible);
  // };

  const onClickHome = useCallback(() => Navigate("/home"),[]) 
  const onClickStockList = useCallback(() => Navigate("/stock_list"),[])
  const onClickOrderList = useCallback(() => Navigate("/order_list"),[])
  const onClickAnalytics = useCallback(() => Navigate("/analytics"),[])
  const onClickAnalyticsReview = useCallback(() => Navigate("/analyticsReview"),[])
  const onClickInfluencerList = useCallback(() => Navigate("/influencer_list"),[])
  const onClickTheme = useCallback(() => Navigate("/theme"),[])

  return (
    <>

<ListItemButton onClick={onClickHome}>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="ホーム"/>
    </ListItemButton>
    <ListItemButton  onClick={onClickStockList}>
      <ListItemIcon>
        <InventoryIcon />
      </ListItemIcon>
      <ListItemText primary="在庫一覧"/>
    </ListItemButton>
    <ListItemButton onClick={onClickOrderList}>
      <ListItemIcon>
        <ShoppingCartIcon />
      </ListItemIcon>
      <ListItemText primary="受注一覧"/>
    </ListItemButton>
    <ListItemButton onClick={onClickAnalytics}>
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="売上・発注・在庫予測"/>
    </ListItemButton>
    <ListItemButton onClick={onClickAnalyticsReview}>
      <ListItemIcon>
        <RateReviewIcon />
      </ListItemIcon>
      <ListItemText primary="レビュー分析"/>
    </ListItemButton>
    <ListItemButton onClick={onClickInfluencerList}>
      <ListItemIcon>
        <AccountCircleIcon />
      </ListItemIcon>
      <ListItemText primary="インフルエンサー"/>
    </ListItemButton>
    <ListItemButton onClick={onClickTheme}>
      <ListItemIcon>
        <ArticleIcon />
      </ListItemIcon>
      <ListItemText primary="テーマ"/>
    </ListItemButton>
      {/* {isVisible && (
        <SidebarContainer>
          <CloseButton onClick={toggleSidebar}><MdClose /></CloseButton>
          <Logo>管理画面</Logo>
          <NavList>
            <NavItem onClick={onClickHome}><MdHome />ホーム</NavItem>
            <NavItem onClick={onClickStockList}><MdInventory />在庫一覧</NavItem>
            <NavItem onClick={onClickOrderList}><MdShoppingCart />受注一覧</NavItem>
            <NavItem onClick={onClickAnalytics}><MdAnalytics />分析</NavItem>
          </NavList>
        </SidebarContainer>
      )}
      {!isVisible && (
        <OpenButton onClick={toggleSidebar}>☰</OpenButton>
      )} */}
    </>
  );
};

// スタイル定義
// const SidebarContainer = styled.div`
//   min-width:150px;
//   background-color: #333; // サイドバーの背景色
//   color: white; // テキストの色
//   height: 100vh; // 画面いっぱいの高さ
//   padding: 20px;
//   position: relative; // 絶対位置の子要素を持つ
//   border-radius:0 20px 20px 0;
// `;

// const CloseButton = styled.button`
//   position: absolute;
//   top: 10px;
//   right: 10px;
//   background: none;
//   border: none;
//   color: white;
//   cursor: pointer;
//   font-size: 24px;
// `;

// const OpenButton = styled.button`
//   font-size: 24px;
//   padding:20px 10px;
//   cursor: pointer;
//   top: 10px;
//   left: 10px;
//   background: none;
//   border: none;
//   color: white;
//   display:flex;
// `;

// const Logo = styled.h1`
//   color: #fff;
//   margin-bottom: 20px; // ロゴ下のスペースを調整
// `;

// const NavList = styled.ul`
//   list-style: none;
//   padding: 0;
//   display: flex;
//   flex-direction: column;
//   flex-grow: 1;
// `;

// const NavItem = styled.li`
//   padding: 10px 0;
//   cursor: pointer;
//   display: flex;
//   align-items: center;
//   svg {
//     margin-right: 10px; // アイコンとテキストの間隔
//   }
//   &:hover {
//     background: #333; // ホバー時の背景色変更
//   }
//   &.logout {
//     margin-top: 500px; // ログアウトの上部のスペースを追加
//   }
// `;