import React, { useState } from 'react';
import styled from 'styled-components';

const TableContainer = styled.div`
  padding: 20px;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
`;

const Th = styled.th`
  background-color: #f5f5f5;
  padding: 12px;
  text-align: left;
  border-bottom: 2px solid #ddd;
  font-weight: 600;
`;

const Td = styled.td`
  padding: 12px;
  border-bottom: 1px solid #ddd;
`;

const FileInput = styled.div`
  margin: 20px;
  
  input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
`;

const ErrorMessage = styled.div`
  color: #ff0000;
  margin: 10px 20px;
`;

const AnalysisSection = styled.div`
  margin: 20px;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  border: 1px solid #e9ecef;
`;

const AnalysisHeader = styled.div`
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 15px;
  color: #495057;
`;

const AnalysisContent = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  color: #6c757d;
`;

const LoadingSpinner = styled.div`
  width: 24px;
  height: 24px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const columns = [
  { id: 'title', label: 'タイトル' },
  { id: 'body', label: '本文' },
  { id: 'rating', label: '評価' },
  { id: 'review_date', label: 'レビュー日' },
  { id: 'source', label: '出典' },
  { id: 'curated', label: '承認状態' },
  { id: 'reviewer_name', label: 'レビュアー名' },
  { id: 'reviewer_email', label: 'メールアドレス' },
  { id: 'product_id', label: '商品ID' },
  { id: 'product_handle', label: '商品ハンドル' },
  { id: 'reply', label: '返信' },
  { id: 'reply_date', label: '返信日' },
  { id: 'picture_urls', label: '画像URL' },
  { id: 'ip_address', label: 'IPアドレス' },
  { id: 'location', label: '場所' },
  { id: 'metaobject_handle', label: 'メタオブジェクトハンドル' }
];

export const FetchAIAnalysis = async (reviews) => {
  let geminiAPIKey = "AIzaSyB7UXeaChiuDm71yK1pJEzfuNKSwCfidP4";
  const ENDPOINT = `https://generativelanguage.googleapis.com/v1beta/models/gemini-2.0-flash-exp:generateContent?key=${geminiAPIKey}`;
  let prompt = `以下は私が経営しているネットショップに寄せられた顧客からのレビューの一覧です。
  
  ${reviews}
  
  このデータを元にレビューの内容を分析してください。商品ハンドル毎の傾向や、高評価、低評価の理由、全体的な雑感など。
  それを踏まえて今後の顧客対応や経営戦略に関するアドバイスをしてください。
  返答はJSONで行ってください。JSONのスキーマは以下の通りです。

  {
    "response": html //3000文字程度の自然な日本語の文章をシンプルなHTMLで見出しや段落を付けながら出力。Divエレメントの内部に埋め込む想定なのでheadなどは書かないこと。
  }
  
  `;
  const payload = {
    method: 'POST',
    headers: {},
    body: JSON.stringify({
      contents: [{ parts: [{ text: prompt }] }],
      generationConfig: {
        "temperature": 1.0,
        "max_output_tokens": 4096
      },
      safetySettings: [
        {
          "category": "HARM_CATEGORY_HATE_SPEECH",
          "threshold": "BLOCK_NONE"
        },
        {
          "category": "HARM_CATEGORY_DANGEROUS_CONTENT",
          "threshold": "BLOCK_NONE"
        },
        {
          "category": "HARM_CATEGORY_HARASSMENT",
          "threshold": "BLOCK_NONE"
        },
        {
          "category": "HARM_CATEGORY_SEXUALLY_EXPLICIT",
          "threshold": "BLOCK_NONE"
        },
        {
          "category": "HARM_CATEGORY_CIVIC_INTEGRITY",
          "threshold": "BLOCK_NONE"
        }
      ]
    })
  };
  const analyticsResponse = await fetch(ENDPOINT, payload);
  const analyticsBody = await analyticsResponse.json();
  const text = analyticsBody.candidates[0].content.parts[0].text;
  let response = "解析に失敗しました。";
  try {
    const responseData = JSON.parse(text.replace('```json', '').replace('```', '').replace(`\n`, ''));
    return responseData.response;
  } catch (error) {
    console.error('Failed to parse JSON:', error);
    return response;
  }
}

const AnalysisResult = ({ text }) => {
  return (
    <div 
      style={{ 
        lineHeight: '1.6',
        padding: '15px'
      }}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
};

export const AnalyticsReview = () => {
  const [reviews, setReviews] = useState([]);
  const [error, setError] = useState('');
  const [analysisResult, setAnalysisResult] = useState('');
  const [isAnalyzing, setIsAnalyzing] = useState(false);

  // CSVの値からダブルクォートを除去する関数
  const removeQuotes = (str) => {
    return str.replace(/^"(.*)"$/, '$1').trim();
  };

  // CSVの行を適切にパースする関数
  const parseCSVLine = (line) => {
    const results = [];
    let field = '';
    let inQuotes = false;
    
    for (let i = 0; i < line.length; i++) {
      const char = line[i];
      
      if (char === '"') {
        if (inQuotes && line[i + 1] === '"') { // エスケープされたクォート
          field += '"';
          i++;
        } else {
          inQuotes = !inQuotes;
        }
      } else if (char === ',' && !inQuotes) {
        results.push(field);
        field = '';
      } else {
        field += char;
      }
    }
    
    results.push(field);
    return results.map(field => removeQuotes(field));
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (!file.name.endsWith('.csv')) {
      setError('CSVファイルのみアップロード可能です。');
      return;
    }

    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const text = e.target.result;
        
        // CSVを行に分割（改行を含むフィールドを考慮）
        const rows = [];
        let currentRow = [];
        let currentField = '';
        let inQuotes = false;
        
        for (let i = 0; i < text.length; i++) {
          const char = text[i];
          const nextChar = text[i + 1];
          
          if (char === '"') {
            if (inQuotes && nextChar === '"') {
              currentField += '"';
              i++;
            } else {
              inQuotes = !inQuotes;
            }
          } else if (char === ',' && !inQuotes) {
            currentRow.push(currentField);
            currentField = '';
          } else if (char === '\n' && !inQuotes) {
            currentRow.push(currentField);
            if (currentRow.length > 0) {
              rows.push(currentRow);
            }
            currentRow = [];
            currentField = '';
          } else if (char === '\r') {
            // キャリッジリターンは無視
            continue;
          } else {
            currentField += char;
          }
        }
        
        // 最後のフィールドと行を処理
        if (currentField) {
          currentRow.push(currentField);
        }
        if (currentRow.length > 0) {
          rows.push(currentRow);
        }

        // ヘッダー行をスキップしてデータを処理
        const parsedReviews = rows.slice(1).map(values => {
          const cleanValues = values.map(value => removeQuotes(value));
          return {
            title: cleanValues[0] || '',
            body: cleanValues[1] || '',
            rating: cleanValues[2] || '',
            review_date: cleanValues[3] || '',
            source: cleanValues[4] || '',
            curated: cleanValues[5] || '',
            reviewer_name: cleanValues[6] || '',
            reviewer_email: cleanValues[7] || '',
            product_id: cleanValues[8] || '',
            product_handle: cleanValues[9] || '',
            reply: cleanValues[10] || '',
            reply_date: cleanValues[11] || '',
            picture_urls: cleanValues[12] || '',
            ip_address: cleanValues[13] || '',
            location: cleanValues[14] || '',
            metaobject_handle: cleanValues[15] || ''
          };
        });

        setReviews(parsedReviews);
        setError('');
        
        // AI分析を実行
        setIsAnalyzing(true);
        try {
          const analysis = await FetchAIAnalysis(text);
          setAnalysisResult(analysis);
        } catch (err) {
          setError('AI分析中にエラーが発生しました。');
          console.error(err);
        } finally {
          setIsAnalyzing(false);
        }
      } catch (err) {
        setError('ファイルの解析中にエラーが発生しました。');
        console.error(err);
      }
    };

    reader.onerror = () => {
      setError('ファイルの読み込み中にエラーが発生しました。');
    };

    reader.readAsText(file, 'UTF-8');
  };

  return (
    <>
      <FileInput>
        <input
          type="file"
          accept=".csv"
          onChange={handleFileUpload}
        />
      </FileInput>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      
      {reviews.length > 0 && (
        <AnalysisSection>
          <AnalysisHeader>AI分析レポート</AnalysisHeader>
          <AnalysisContent>
            {isAnalyzing ? (
              <>
                <LoadingSpinner />
                <span>AIがレビューの内容を分析しています</span>
              </>
            ) : (
              <AnalysisResult text={analysisResult} />
            )}
          </AnalysisContent>
        </AnalysisSection>
      )}

      <TableContainer>
        <Table>
          <thead>
            <tr>
              {columns.map((column) => (
                <Th key={column.id}>{column.label}</Th>
              ))}
            </tr>
          </thead>
          <tbody>
            {reviews.map((review, index) => (
              <tr key={index}>
                {columns.map((column) => (
                  <Td key={column.id}>{review[column.id]}</Td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
    </>
  );
};